<template>
	<h1>
		<em>Room :&nbsp; </em
		><router-link
			:to="{
				name: 'Room',
				params: { id: room.owner.id },
			}"
			>{{ room.name }}</router-link
		>
	</h1>
	<div id="functions">
		<ActionEndorse entityType="room" :entity="room" class="bump-r" />
		<FormButton :level="2" value="Delete" @click="deleteRoom" class="bump-r" />
	</div>

	<NavSub :routed="true" :items="navItems" />
	<router-view :entity="room" />
</template>

<script>
import ActionEndorse from '@/components/ActionEndorse'
import FormButton from '@/components/FormButton'
import NavSub from '@/components/NavSub'
import { ClusterActions } from '@/use/EntityActions'

export default {
	name: 'AdminInspectRoom',
	components: { ActionEndorse, FormButton, NavSub },
	props: {
		room: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			navItems: [
				{
					display: 'Info',
					to: {
						name: 'AdminInspectEntityInfo',
					},
				},
				{
					display: 'Artworks',
					to: {
						name: 'AdminInspectEntityArtworks',
					},
				},
				{
					display: 'Artwork Discrepancies',
					to: {
						name: 'AdminInspectEntityDiscrep',
						params: { relationType: 'artworks' },
					},
				},
				{
					display: 'JSON',
					to: {
						name: 'AdminInspectEntityJson',
					},
				},
			],
		}
	},
	mounted() {
		this.clusterActions = new ClusterActions(this.$imperial)
	},
	methods: {
		deleteRoom() {
			this.clusterActions.delete(this.room.id, 'room')
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity.scss"></style>
