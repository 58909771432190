<template>
	<h1>
		<em>User </em
		><router-link :to="{ name: 'User', params: { username: user.username } }">{{
			user.name
		}}</router-link>
	</h1>
	<div id="functions">
		<ActionEndorse entityType="user" :entity="user" class="bump-r" />
		<FormButton :level="2" value="Delete" @click="deleteUser" class="bump-r" />
	</div>

	<NavSub :routed="true" :items="navItems" />
	<router-view :entity="user" />
</template>

<script>
import ActionEndorse from '@/components/ActionEndorse'
import FormButton from '@/components/FormButton'
import NavSub from '@/components/NavSub'
import { UserActions } from '@/use/EntityActions'

export default {
	name: 'AdminInspectUser',
	components: { ActionEndorse, FormButton, NavSub },
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			navItems: [
				{
					display: 'Info',
					to: {
						name: 'AdminInspectEntityInfo',
					},
				},
				{
					display: 'Artworks',
					to: {
						name: 'AdminInspectEntityArtworks',
					},
				},
				{
					display: 'Lists',
					to: {
						name: 'AdminInspectEntityLists',
					},
				},
				{
					display: 'Rooms',
					to: {
						name: 'AdminInspectEntityRooms',
					},
				},
				{
					display: 'Artwork Discrepancies',
					to: {
						name: 'AdminInspectEntityDiscrep',
						params: { relationType: 'artworks' },
					},
				},
				{
					display: 'JSON',
					to: {
						name: 'AdminInspectEntityJson',
					},
				},
			],
		}
	},
	mounted() {
		this.userActions = new UserActions(this.$imperial)
	},
	methods: {
		deleteUser() {
			this.userActions.delete(this.user.id)
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity.scss"></style>
<!-- <style lang="scss" scoped>
#functions {
	background: #333;
}
</style> -->
