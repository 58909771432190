<template>
	<h1 id="title">
		<em
			><span v-if="artwork.takenDown">Hidden Artwork</span
			><template v-else>Artwork</template> :&nbsp; </em
		><router-link
			:to="{
				name: 'AdminInspectEntity',
				params: {
					entityType: 'artist',
					id_username_namePath: artwork.artistNamePath,
				},
			}"
			>{{ artwork.artistName }}</router-link
		><em> &nbsp;&rsaquo;&nbsp; </em
		><router-link
			:to="{
				name: 'Artwork',
				params: {
					category: artwork.category,
					namePath: artwork.artistNamePath,
					titlePath: artwork.titlePath,
				},
			}"
			>{{ artwork.title }}</router-link
		>
	</h1>
	<div id="functions">
		<ActionEndorse entityType="artwork" :entity="artwork" class="bump-r" />
		<FormButton :level="2" value="Delete" @click="deleteArtwork" class="bump-r" />
	</div>
	<NavSub :routed="true" :items="navItems" />
	<router-view :entity="artwork" />
</template>

<script>
import ActionEndorse from '@/components/ActionEndorse'
import FormButton from '@/components/FormButton'
import NavSub from '@/components/NavSub'
import { ItmActions } from '@/use/EntityActions'

export default {
	name: 'AdminInspectArtwork',
	components: { ActionEndorse, FormButton, NavSub },
	props: {
		artwork: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			navItems: [
				{
					display: 'Info',
					to: {
						name: 'AdminInspectEntityInfo',
					},
				},
				{
					display: 'Collectors',
					to: {
						name: 'AdminInspectEntityCollectors',
					},
				},
				{
					display: 'Lists',
					to: {
						name: 'AdminInspectEntityLists',
					},
				},
				{
					display: 'Rooms',
					to: {
						name: 'AdminInspectEntityRooms',
					},
				},
				{
					display: 'Collector Discrepancies',
					to: {
						name: 'AdminInspectEntityDiscrep',
						params: { relationType: 'collectors' },
					},
				},
				{
					display: 'Room Discrepancies',
					to: {
						name: 'AdminInspectEntityDiscrep',
						params: { relationType: 'rooms' },
					},
				},
				{
					display: 'JSON',
					to: {
						name: 'AdminInspectEntityJson',
					},
				},
			],
		}
	},
	mounted() {
		this.itmActions = new ItmActions(this.$imperial)
	},
	methods: {
		deleteArtwork() {
			this.itmActions.delete(this.artwork.id, 'artwork')
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity.scss"></style>
