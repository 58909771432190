<template>
	<template v-if="error">
		<p class="error-msg">{{ error }}</p>
		<router-link :to="{ name: 'AdminInspect' }">Exit</router-link>
	</template>
	<h1 v-if="loading && !error"><em>Loading...</em></h1>
	<template v-if="!loading && !error">
		<Artist v-if="entityType == 'artist'" :artist="entity" />
		<Artwork v-if="entityType == 'artwork'" :artwork="entity" />
		<User v-if="entityType == 'user'" :user="entity" />
		<List v-if="entityType == 'list'" :list="entity" />
		<Room v-if="entityType == 'room'" :room="entity" />
	</template>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Views
import Artist from '@/views/Admin/Inspect/Artist'
import Artwork from '@/views/Admin/Inspect/Artwork'
import User from '@/views/Admin/Inspect/User'
import List from '@/views/Admin/Inspect/Room'
import Room from '@/views/Admin/Inspect/Room'

// Internal
import { titlefy } from '@/use/StringMagic'
import { injectMetaData } from '@/use/MetaData'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'AdminInspectEntity',
	components: {
		Artist,
		Artwork,
		User,
		List,
		Room,
	},
	props: {
		// Router passive
		// These are passed to child components and cause
		// warnings to be thrown in console if missing
		id_username_namePath_wildcard: String,
		page: [String, Number],
		relationType: String,

		// Router
		entityType: {
			type: String,
			required: true,
		},
		id_username_namePath: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		// // More advanced page title but it requires
		// // reworking entire page to composition api
		// const title = computed(() => {
		// 	const entityName =
		// 		context.entity && context.entity.id
		// 			? props.entityType == 'artwork'
		// 				? context.entity.title
		// 				: context.entity.name
		// 			: null

		// 	let titleValue = `Inspect ${titlefy(props.entityType)}`
		// 	if (entityName) titleValue += `: ${entityName}`
		// 	return titleValue
		// })
		injectMetaData({
			title: `Admin: Inspect ${titlefy(props.entityType)} - ${props.id_username_namePath}`,
		})

		const apiStore = useApiStore()
		const adminInspectApi = apiStore.loadApi('adminInspect')

		return { adminInspectApi }
	},
	data() {
		return {
			loading: true,
			error: false,
			entity: {},
		}
	},
	watch: {
		id_username_namePath() {
			this.init()
		},
	},
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			this.loading = true
			const { status, data, statusText } = await this.adminInspectApi.inspectEntity(
				this.entityType,
				this.id_username_namePath
			)
			console.log(55, data)

			// When username is used in URL, replace it with ID
			// This allows us to call getFeed and saves us general hassle later
			if (this.id_username_namePath != data.id) {
				const redirect = {
					name: this.$route.name,
					params: { ...this.$route.params },
					query: { ...this.$route.query },
				}
				redirect.params.id_username_namePath = data.id
				this.$router.push(redirect)
			}

			this.loading = false

			// Handle errors
			if (status == 200) {
				this.entity = data
			} else {
				handleApiError({ status, data, statusText, action: 'inspecting this ' + this.entityType })
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
