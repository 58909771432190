<template>
	<h1 id="title">
		<em
			><span v-if="artist.takenDown">Artist (Taken Down)</span
			><template v-else>Artist</template> :&nbsp; </em
		><router-link
			:to="{ name: 'Artist', params: { namePath: artist.namePath, category: artist.category } }"
			>{{ artistName }}</router-link
		>
	</h1>
	<div id="functions">
		<ActionEndorse entityType="artist" :entity="artist" class="bump-r" />
		<FormButton :level="2" value="Delete" @click="deleteArtist" class="bump-r" />
		<FormButton v-if="takenDown" :level="2" value="Restore" @click="restoreArtist" class="bump-r" />
		<FormButton v-else :level="2" value="Take Down" @click="takeDownArtist" class="bump-r" />
	</div>
	<NavSub :routed="true" :items="navItems" />
	<router-view :entity="artist" />
</template>

<script>
// Components
import ActionEndorse from '@/components/ActionEndorse'
import FormButton from '@/components/FormButton'
import NavSub from '@/components/NavSub'

// Internal
import { ArtistActions } from '@/use/EntityActions'

export default {
	name: 'AdminInspectArtist',
	components: { ActionEndorse, FormButton, NavSub },
	props: {
		artist: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			takenDown: this.artist.takenDown,
			navItems: [
				{
					display: 'Info',
					to: {
						name: 'AdminInspectEntityInfo',
					},
				},
				{
					display: 'Artworks',
					to: {
						name: 'AdminInspectEntityArtworks',
					},
				},
				{
					display: 'Artwork Discrepancies',
					to: {
						name: 'AdminInspectEntityDiscrep',
						params: { relationType: 'artworks' },
					},
				},
				{
					display: 'JSON',
					to: {
						name: 'AdminInspectEntityJson',
					},
				},
			],
		}
	},
	computed: {
		artistName() {
			if (this.artist.firstName && this.artist.lastName) {
				return this.artist.firstName + ' ' + this.artist.lastName
			} else if (this.artist.name) {
				return this.artist.name
			} else {
				return this.artist.lastName
			}
		},
	},
	mounted() {
		this.artistActions = new ArtistActions(this.$imperial)
	},
	methods: {
		deleteArtist() {
			this.artistActions.delete(this.artist.id)
		},
		takeDownArtist() {
			this.artistActions.takeDown(this.artist.id, success => {
				this.takenDown = success === true
			})
		},
		restoreArtist() {
			this.artistActions.restore(this.artist.id, success => {
				this.takenDown = success !== true
			})
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity.scss"></style>
